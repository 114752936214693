// @ts-check

import { isNumber } from "lodash";

export function base64_to_array_buffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.codePointAt(i);
  }
  return bytes.buffer;
}
/**
 * @typedef {import('@/types/General.d.js').Property} Property
 * @typedef {import('@/types/General.d.js').PropertyUnit} PropertyUnit
 * @typedef {import('@/types/General.d.js').Measurement} Measurement
 */

/** SETUP starts here */
export const metric_prefixes = {
  k: 3,
  M: 6,
  G: 9,
  m: -3,
  µ: -6,
  n: -9,
  p: -12,
};

/**
 * The value's units (degrees) in which units are stored in db (aka base).
 * N.B: This object only features physical properties.
 */
export const units_base = {
  volume: "uL",
  mass: "mg",
  amount: "nmol",
  atomic_mass: "Da",
  mw: "g/mol",
  unit: "-",
  density: "g/mL",
  c: "M",
  count: "pcs",
  tubes: "pcs",
  au: "au",
  fraction: "%",
};

/**
 * N.B: This object only features physical properties.
 */
export const unit_standards = {
  volume: "L",
  mass: "g",
  amount: "mol",
  atomic_mass: "Da",
  c: "M",
  density: "g/mL",
  au: "au",
  unit: "-",
  fraction: "%",
};

export const metric_units = ["m", "mol", "AU"];

/** @type {Record<Property, Record<string, number>>} */
export const units_to_degree = {
  unit: { // maths, base for eq
    "-": 0,
  },
  fraction: { // base for mass_fraction
    "%": 0,
  },

  volume: { // solution/combination quantitative properties
    uL: 0,
    mL: 3,
    L: 6,
  },
  mass: {
    pg: -9,
    ng: -6,
    ug: -3,
    mg: 0,
    g: 3,
    kg: 6,
  },
  count: {
    pcs: 0,
  },
  tubes: {
    pcs: 0,
  },
  amount: {
    nmol: 0,
    umol: 3,
    mmol: 6,
    mol: 9,
  },
  au: {
    au: 0,
  },

  density: { // qualitative properties
    "g/mL": 0,
  },
  atomic_mass: {
    Da: 0,
    kDa: 3,
    MDa: 6,
  },
  mw: {
    "g/mol": 0,
  },
  c: {
    nM: -9,
    uM: -6,
    mM: -3,
    M: 0,
  },
};

/** @type {Record<string, Property>} */
export const sample_to_physical = Object.freeze({
  mass: "mass",
  amount: "amount",
  volume: "volume",
  au: "au",
  tare: "mass",
  count: "count",
  tubes: "tubes",
});

/** @type {Property[]} */
// @ts-ignore
const supported_properties_arr = Object.keys(units_to_degree);
const supported_properties_str = supported_properties_arr.join(" | ");

/** @type {Set<Property>} */
// @ts-ignore
const supported_properties = new Set(Object.keys(units_to_degree));
/** SETUP ends here */

/**
 * Representing the number to the specified precision.
 * @param {Number} num - the value to be trimmed
 * @param {Number} precision - is the number of significant digits. Default value is 3. Should be between 1 and 100 (inclusive).
 * @returns trimmed number
 */
export const format_num = (num, precision = 3) => {
  if (!num) return num;
  // toPrecision returns a string and utilizes exponential notation like '1.123e+7'.
  // so parseFloat is needed
  return Number.parseFloat(num.toPrecision(precision));
};

/**
 * This is introduced, because not all properties
 * need to be rounded to significant digits when formatted for display (e.g. sample_state.meta.properties.count);
 * (Oh how I wonder if it's gonna come back some day to bite us in the ass)
 */
export const skip_rounding_set = new Set(["count", "tubes"]);

/**
 * Transform object units_to_degree so that
 * degrees are keys, and units a values
 */
export const degrees_to_units = (() => {
  const res = {};
  for (const [property, value] of Object.entries(units_to_degree)) {
    res[property] = {};
    for (const [deg_str, deg] of Object.entries(value)) {
      res[property][deg] = deg_str;
    }
  }
  return res;
})();

/**
 * Converts passed value to a 3-digit form with the appropriate dimensional suffix.
 * @param {Number} value - in standard units (mass - mg, volume - uL, amount - nmol)
 * @param {Property} property
 * @returns
 */
export const prettify = (value, property) => {
  if (!supported_properties.has(property)) {
    throw new Error(`prettify(): unknown property ${property}\nSupported properties: ${supported_properties_str}`);
  }
  const max_pow_available = Math.max(...Object.values(units_to_degree[property]));
  const min_pow_available = Math.min(...Object.values(units_to_degree[property]));
  let pow = Math.floor(Math.log10(value) / 3) * 3;
  pow = pow < min_pow_available
    ? min_pow_available
    : (pow > max_pow_available
      ? max_pow_available
      : pow);
  const target_pow_string = degrees_to_units[property][`${pow}`];
  const target_pow = units_to_degree[property][target_pow_string];

  const target_degree_val = value / 10 ** (target_pow);
  return skip_rounding_set.has(property)
    ? `${Math.round(target_degree_val * 100) / 100} ${target_pow_string}`
    : `${format_num(target_degree_val)} ${target_pow_string}`;
};

/**
 * signed_prettify = prettify with negative value support
 * @param {Number} value (see 'prettify' description above)
 * @param {Property} property (see 'prettify' description above)
 * @returns
 */
export const signed_prettify = (value, property) => {
  if (!supported_properties.has(property)) {
    throw new Error(`signed_prettify(): unknown property ${property}\nSupported properties: ${supported_properties_str}`);
  }
  const sign = Math.sign(value);
  return `${sign < 0 ? "-" : ""}${prettify(sign * value, property)}`;
};

/**
 *
 * @param {Property} property
 * @param {Number} value - number to be converted to a different degree of 10
 * @param {String} value_unit - initial degree of 10 (mL, mg)
 * @returns
 */
export const trim_number_degree = (property, value, value_unit) => {
  if (!supported_properties.has(property)) {
    throw new Error(`trim_number_degree(): unknown property ${property}\nSupported properties: ${supported_properties_str}`);
  }
  const pow = units_to_degree[property][value_unit];
  if (pow === undefined) {
    return `Unknown unit: "${value_unit}"`;
  }
  const norm_val = value * 10 ** pow;
  return norm_val;
};

/**
 * Turns decimal minutes (e.g. 10.988 min) into clock-like timestamp
 * @param {Number} minutes
 * @returns trimmed string
 */
export const decimal_min_to_ss = (minutes) => {
  const min = Math.floor(Math.abs(minutes));
  const sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return `${(min < 10 ? "0" : "")}${min}:${(sec < 10 ? "0" : "")}${sec}`;
};

export const transliterate = (word) => {
  const a = {
    Ё: "YO", Й: "I", Ц: "TS", У: "U", К: "K", Е: "E", Н: "N", Г: "G", Ш: "SH", Щ: "SCH", З: "Z", Х: "H", Ъ: "'", ё: "yo", й: "i", ц: "ts", у: "u", к: "k", е: "e", н: "n", г: "g", ш: "sh", щ: "sch", з: "z", х: "h", ъ: "'", Ф: "F", Ы: "I", В: "V", А: "A", П: "P", Р: "R", О: "O", Л: "L", Д: "D", Ж: "ZH", Э: "E", ф: "f", ы: "i", в: "v", а: "a", п: "p", р: "r", о: "o", л: "l", д: "d", ж: "zh", э: "e", Я: "Ya", Ч: "CH", С: "S", М: "M", И: "I", Т: "T", Ь: "'", Б: "B", Ю: "YU", я: "ya", ч: "ch", с: "s", м: "m", и: "i", т: "t", ь: "'", б: "b", ю: "yu",
  };
  return [...word].map((char) => a[char] || char).join("");
};

export const to_superscript = (digit) => ({
  "-": "⁻",
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
})[`${digit}`] || "";

/**
 * Converts mod to units
 * @param {Property} property - the key of units_to_degree
 * @returns {String} - converted value
 */
export const convert_mod_to_base_unit = (property) => {
  if (!supported_properties.has(property)) {
    throw new Error(`convert_mod_to_base_unit(): unknown property ${property}\nSupported properties: ${supported_properties_str}`);
  }
  return units_base[property];
};

/**
 * Converts passed mod value into the DB-stored degree/format
 * @param {Property} property - a key from {@link units_base}
 * @returns {string} = a value from {@link units_base}
 */
export const get_base_unit_of_mod = (property) => {
  if (!supported_properties.has(property)) {
    throw new Error(`get_base_unit_of_mod(): unknown property ${property}\nSupported properties: ${supported_properties_str}`);
  }
  return units_base[property];
};

const mod_by_unit = (() => {
  const res = {};
  for (const [property, value] of Object.entries(units_to_degree)) {
    for (const deg_str of Object.keys(value)) {
      if (res.hasOwnProperty(deg_str)) {
        res[deg_str] = [res[deg_str], property].flat();
      } else {
        res[deg_str] = property;
      }
    }
  }
  return res;
})();

/**
 * Converts value from specified units to base units (uL, mg, nmol, Da, M, g/mol etc)
 * From base to unit if to_unit=true
 * @param {Number} value - number to be converted
 * @param {PropertyUnit} unit - the units of the value
 * @param {Property} property - the key of units_to_degree
 * @param {Boolean} to_unit - if true -> the {@link value} is converted from base to {@link unit}. False by default.
 * @returns {Number} - converted value
 */
export const convert_to_base_unit = (value, unit, property, to_unit = false) => {
  if (!isNumber(value) || Number.isNaN(value)) {
    throw new TypeError(`convert_to_base_unit(): not a number for "${property}": value=${value} unit=${unit}`);
  }
  if (!supported_properties.has(property)) {
    throw new Error(`convert_to_base_unit(): unknown property ${property}\nSupported properties: ${supported_properties_str}`);
  }
  if (!(unit in units_to_degree[property])) {
    throw new Error(`convert_to_base_unit(): unknown unit ${unit} for property ${property}\nSupported units: ${Object.keys(units_to_degree[property]).join(", ")}`);
  }
  const unit_deg = units_to_degree[property][unit];
  const base_deg = units_to_degree[property][units_base[property]];

  const deg_diff = to_unit ? -(unit_deg - base_deg) : (unit_deg - base_deg);
  const res = value * 10 ** deg_diff;
  if (Number.isNaN(res)) {
    throw new TypeError(`convert_to_base_unit(): got NaN for "${property}": value=${value} unit=${unit}`);
  }
  return res;
};

export const get_property_by_unit = (unit) => {
  if (unit in mod_by_unit) {
    return mod_by_unit[unit];
  }
  throw new Error(`src/lib/conversions.js: unknown units '${unit}'`);
};

/**
 * Converts base-degree unit to standard-degree unit value
 * @param {Measurement} measurement
 * @param {keyof unit_standards} property
 * @returns {number}
 */
export const convert_to_standard_unit = (measurement, property) => {
  try {
    const standard_unit = unit_standards[property];
    return convert_to_base_unit(measurement.value, standard_unit, property, true);
  } catch (error) {
    throw new Error(`convert_to_standard_unit(): failed to convert ${JSON.stringify(measurement)}:\n\t${error}`);
  }
};

/**
 * Utilizes prettify function to find the most appropriate unit.
 * Round the value.
 * @param {Measurement} measurement
 * @param {Property} property
 * @returns {Measurement}
 */
export function prettify_unit({ value, unit }, property) {
  if (value === null) {
    return { value, unit };
  }
  return {
    value,
    unit: prettify(value, property).split(" ")[1],
  };
}
/**
 * Converts input string to string where all non-word characters replaced by "_"
 * @param {string} input
 * @returns string
 */
export const convert_to_valid_html_id = (input) => input
  .replace(/\W/g, "_")
  .toLowerCase();
