import {
  ApolloLink,
  InMemoryCache,
  ApolloClient,
  HttpLink,
  from,
  split,
} from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import Vue from "vue";
import VueApollo from "vue-apollo";

Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";
const USER_STORAGE_KEY = "user";

const split_link = () => {
  let hdr_role = "";
  let hdr_auth = "";
  const http_link = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
  });

  const ws_link = new GraphQLWsLink(
    createClient({
      url: process.env.VUE_APP_GRAPHQL_WS,
      connectionParams: () => ({
        headers: {
          "Sec-WebSocket-Protocol": "graphql-ws",
          Authorization: hdr_auth,
          "x-hasura-role": hdr_role,
        },
      }),
    }),
  );

  return split(
    (
      { query, getContext },
    ) => {
      const definition = getMainDefinition(query);
      const context = getContext();
      if (context.headers) {
        hdr_role = context.headers["X-Hasura-Role"];
        hdr_auth = context.headers.Authorization;
      }
      return (
        definition.kind === "OperationDefinition"
        && definition.operation === "subscription"
      );
    },
    ws_link,
    http_link,
  );
};

const error_link = onError(
  ({
    graphQLErrors, networkError, operation, forward,
  }) => {
    if (
      graphQLErrors
      && graphQLErrors.length > 0
      && graphQLErrors[0].extensions
      && graphQLErrors[0].extensions.code === "invalid-jwt"
    ) {
      localStorage.removeItem(AUTH_TOKEN);
      window.location = "/login";
      return;
    }
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        // eslint-disable-next-line no-console
        console.log(
          "%cError",
          "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
          error.message,
          error.stack,
        );
      }
    }
    if (networkError) console.warn(`[Network error]: ${networkError}`);
    forward(operation);
  },
);

// custom Apollo link for Hasura requests. Checks requested entities,
// and performs requests with the specified role - sets 'x-hasura-role' header
const hasura_link = new ApolloLink((operation, forward) => {
  const role_priorities = {
    User: 1,
    superuser: 50,
  };
  const role_priority_sorter = (a, b) => role_priorities[b] - role_priorities[a];

  const context = operation.getContext();
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    context.headers = context.headers
      ? { Authorization: `Bearer ${token}`, ...context.headers }
      : { Authorization: `Bearer ${token}` };
  }
  // header can be overridden in downstream client code;
  // for example, introspection code does it on login
  if (
    !context.headers
    || Object.keys(context.headers)
      .map((e) => e.toLowerCase())
      .includes("x-hasura-role")
  ) {
    return forward(operation);
  }
  // this is set by hasura-user plugin on login. We only care to set headers for logged in users
  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
  if (!user) return forward(operation);

  if (!user.capacities) return forward(operation);

  // now we take all the roles user has, and strike out those not allowed for this query
  let roles = [...user.roles];

  // admins always execute all queries in admin context unless explicitly overridden by client code
  if (roles.includes("superuser")) {
    operation.setContext({
      headers: { ...context.headers, "X-Hasura-Role": "superuser" },
    });
    return forward(operation);
  }

  for (const def of operation.query.definitions) {
    // this can also be a FragmentDefinition, we don't care
    if (def.kind !== "OperationDefinition") continue;
    // this can be query, mutation, or probably something else like subscription
    const op_type = def.operation;
    for (const selection of def.selectionSet.selections) {
      const name = selection.name.value;
      if (op_type === "mutation") {
        if (!user.capacities.mutations[name]?.roles) {
          // cuz we take into account mutation access by @include directive
          if (!selection.directives.some(({ name: { value } }) => value === "include")) {
            console.warn(
              `Mutation ${name} is not allowed for user with roles ${user.roles}. Probably, there is an error with the configuration of rights.`,
            );
          }
          continue;
        }
        const capacity_set = new Set(user.capacities.mutations[name].roles);
        roles = roles.filter((e) => capacity_set.has(e));
      } else {
        // query or subscription
        if (!user.capacities.queries[name]) {
          console.warn(
            `${op_type} ${name} is not allowed for user with roles ${user.roles}. Probably, there is an error with the configuration of rights.`,
          );
          continue;
        }
        const capacity_set = new Set(user.capacities.queries[name].roles);
        roles = roles.filter((e) => capacity_set.has(e));
      }
      // this should not normally happen
      // !ohh boy, it will though!
      if (!roles) break;
    }
  }
  if (roles.length === 0) {
    console.warn(
      `No suitable roles found for user with roles ${user.roles} to execute API request ${operation.operationName} defined as that: '${operation.query.loc.source.body}'`,
    );
  } else {
    // we pick the last role of those suitable (presuming, it has more rights)
    operation.setContext({
      headers: { ...context.headers, "X-Hasura-Role": roles.sort(role_priority_sorter)[0] },
    });
  }
  return forward(operation);
});

// Config
const defaultOptions = {
  httpEndpoint: process.env.VUE_APP_GRAPHQL_HTTP,
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: from([hasura_link, error_link, split_link()]),

  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          /**
           * for every one of these fields 'args' array looks like this:
           *  [
           *    existing: any[],
           *    incoming: any[],
           *    utils_object: { ... }, // refer to apollo docs for this one
           *  ]
           */
          equipment_type: {
            merge(_, new_val) {
              // just take 'incoming' as the new array
              return new_val;
            },
          },
          housing_type: {
            merge(_, new_val) {
              return new_val;
            },
          },
          vessel_type: {
            merge(_, new_val) {
              return new_val;
            },
          },
          operation_type: {
            merge(_, new_val) {
              return new_val;
            },
          },
          operation: {
            merge(_, new_val) {
              return new_val;
            },
          },
          workflow_profile: {
            merge(_, new_val) {
              return new_val;
            },
          },
          printer: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },
      Subscription: {
        fields: {
          dataset: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },
      compound: {
        fields: {
          compound_substances: {
            merge(_, new_val) {
              return new_val;
            },
          },
          compound_properties: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },
      group_operation: {
        fields: {
          operation_type: {
            merge(_, new_val) {
              return new_val;
            },
          },
          housing_states_in: {
            merge(_, new_val) {
              return new_val;
            },
          },
          housing_states_out: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },

      product_compound: {
        keyFields: ["product_id", "compound_id"],
      },
      compound_sku: {
        keyFields: ["compound_id", "sku_id"],
      },
      target_sample: {
        keyFields: ["target_id", "sample_id"],
      },
      user_tag: {
        keyFields: ["user_id", "tag_id"],
      },
      // `cache.identify` will not work for `tag_compound` without adding the following policy to typePolicies (in vue-apollo.js):
      tag_compound: { keyFields: ["tag_id", "compound_id"] },

      housing_type: {
        keyFields: ["key"],
        fields: {
          housing_type_vessel_types: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },
      vessel_type: {
        fields: {
          housing_type_vessel_types: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },
      workflow_profile: {
        fields: {
          operation_type_workflow_profiles: {
            merge(_, new_val) {
              return new_val;
            },
          },
        },
      },
    },
  }),
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const apolloClient = new ApolloClient({
    ...defaultOptions,
    ...options,
  });

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
  });
  return apolloProvider;
}

// Manually call this when user logs in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  try {
    await apolloClient.resetStore();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", error.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", error.message);
  }
}
