/*= ========================================================================================
  Section name: Config, Oligos
  Inner routes: monomers
========================================================================================== */

export default [
  {
    path: "/config/oligos/monomers",
    name: "config:monomers",
    meta: {
      menu_name: "Monomers",
      menu_section: "oligos_config",
      icon: "mdi-dna",
      required_capacities: ["config"],
      required_teams: ["Oligosynthesis"],
    },
    component: () => import("@/views/config/oligos/monomers.vue"),
  },
  {
    path: "/config/oligos/monomers/:id/edit",
    name: "config:monomers:edit",
    meta: {
      icon: "mdi-dna",
      required_capacities: ["config"],
    },
    component: () => import("@/views/config/oligos/monomers.vue"),
  },
];
