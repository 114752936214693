<template>
  <v-card
    outlined
    elevation="0"
    class="ma-4"
  >
    <v-card-title class="bonus_header d-flex justify-start justify-sm-center w-100">
      <span class="ml-2 text-h5">Monthly Bonus</span>
      <span id="add_monthly_bonus">
        <v-icon
          v-if="user_can_edit"
          class="ml-2"
          @click="on_add_bonus"
        >
          mdi-plus-circle-outline
        </v-icon>
      </span>
      <div
        v-if="bonuses_monthly"
        id="show_disabled_rows_chbox"
        class="disabled_checkbox d-flex align-center"
      >
        <v-switch
          v-model="show_disabled"
          inset
          flat
          :ripple="false"
          class="mt-0 pa-0"
          hide-details
        />
        <v-card-text class="disabled_checkbox_label pa-0 pr-4">
          Show disabled
        </v-card-text>
      </div>
    </v-card-title>

    <div id="monthly_bonus_table">
      <v-data-table
        class="transparent c-table mx-4"
        :headers="headers"
        :items="filtered_bonuses"
      >
        <template #item.accrued_by="{ item: { id, creator: { first_name, last_name }, created_at } }">
          <div
            :id="`creator_name_${id}`"
            class="text-capitalize font-weight-bold"
          >
            {{ first_name }} {{ last_name[0] }}.
          </div>
          <div :id="`created_at_${id}`">
            {{ format_date(new Date(created_at)) }}
          </div>
        </template>

        <template #item.bonus="{ item: { id, value, accrued_day_of_month } }">
          <div
            :id="`accrued_day_${id}`"
            class="font-weight-bold"
          >
            every {{ accrued_day_of_month }}
          </div>
          <div
            v-if="value > 0"
            :id="`pay_value_${id}`"
            class="badge"
          >
            +{{ kopecks_currency_units(value, "toRubles") }} ₽
          </div>
          <div
            v-else
            :id="`pay_value_${id}`"
            class="badge"
          >
            -{{ Math.abs(kopecks_currency_units(value, "toRubles")) }} ₽
          </div>
        </template>

        <template #item.comment="{ item: { id, text } }">
          <div
            :id="`bonus_comment_${id}`"
            class="comment_text"
          >
            {{ text }}
          </div>
        </template>

        <template #item.actions="{ item: bonus }">
          <div
            class="d-flex justify-end w-100"
          >
            <div
              :id="`bonus_edit_btn_${bonus.id}`"
              :class="{ disabled: !bonus.enable }"
            >
              <v-icon
                v-if="user_can_edit"
                size="22"
                class="ml-4 mb-1"
                @click="on_edit_bonus(bonus)"
              >
                mdi-pencil-outline
              </v-icon>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <div
      v-if="modal.show"
    >
      <v-dialog
        id="monthly_bonus_modal"
        :value="modal.show"
        max-width="400px"
        @input="on_close_modal"
      >
        <v-card class="pb-4">
          <span class="d-flex justify-center text-h5 pa-4">Monthly Bonus</span>

          <v-card-text class="w-100 pa-4 pt-0">
            <v-row>
              <v-spacer class="pa-3" />
              <v-col>
                <div
                  id="bonus_modal_enable"
                  class="d-flex align-center"
                >
                  <v-switch
                    v-model="edited_bonus.enable"
                    inset
                    flat
                    :ripple="false"
                    class="mt-0 pa-0"
                    hide-details
                  />
                  <span>Enable</span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                Value
              </v-col>
              <v-col
                id="bonus_modal_value"
                class="modal_field_wrapper"
              >
                <v-text-field
                  v-model="edited_bonus.value"
                  dense
                  outlined
                  type="number"
                  suffix="₽"
                  hide-details="auto"
                  :rules="rules_validation('value', [
                    (val) => !!val || 'Required field',
                  ])"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                Accrued day of month
              </v-col>
              <v-col
                id="bonus_modal_accrued_day"
                class="modal_field_wrapper"
              >
                <v-text-field
                  v-model="edited_bonus.accrued_day_of_month"
                  dense
                  outlined
                  type="number"
                  hide-details="auto"
                  :rules="rules_validation('accrued_day_of_month', [
                    (val) => !!val || 'Required field',
                    (val) => (val > 0 && val < 31) || 'Allowed interval 1 - 30',
                  ])"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col id="bonus_modal_comment">
                <span class="d-flex font-weight-bold mb-2">Comment</span>
                <v-textarea
                  v-model="edited_bonus.text"
                  dense
                  outlined
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-space-between pt-2 pb-0">
            <v-hover v-slot="{ hover }">
              <span id="bonus_modal_save">
                <v-btn
                  depressed
                  :disabled="!has_changes"
                  class="btn-save blue white--text"
                  :class="{ 'darken-2': hover }"
                  @click="on_save"
                >
                  <span>{{ modal.confirm_text }}</span>
                </v-btn>
              </span>
            </v-hover>
            <v-btn
              depressed
              class="btn-cancel transparent"
              @click="on_close_modal"
            >
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { clone } from "lodash";
import { format_date } from "@/lib/date";
import { error_handler } from "@/lib/util.js";

export default {
  name: "MonthlyBonus",

  props: {
    edited_user_id: {
      type: Number,
      default: null,
    },
    user_can_edit: {
      type: Boolean,
    },
  },
  apollo: {
    bonuses_monthly: {
      query: require("@/graphql/config/bonus_monthly/get_user_bonuses_monthly.gql"),
      variables() {
        return {
          where: { user_id: { _eq: this.edited_user_id } },
        };
      },
      update({ bonus_monthly }) {
        this.$emit("update", bonus_monthly);
        return bonus_monthly;
      },
      skip() {
        return !this.$user.uid;
      },
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Accrued by",
          value: "accrued_by",
          sortable: false,
        },
        {
          text: "Bonus",
          value: "bonus",
          sortable: false,
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      show_disabled: false,
      modal: {
        show: false,
        confirm_text: "Save",
      },
      has_changes: false,
      validation_errors: {},
      edited_bonus: {},
    };
  },
  computed: {
    filtered_bonuses() {
      if (!this.bonuses_monthly) return [];
      return this.bonuses_monthly.filter((bonus) => this.show_disabled || bonus.enable);
    },
  },
  watch: {
    edited_bonus: {
      deep: true,
      handler() {
        this.check_changes();
      },
    },
  },
  methods: {
    format_date,
    rules_validation(value_name, conditions = []) {
      const value = this.edited_bonus[value_name];
      this.validation_errors[value_name] = conditions.map((fn) => fn(value));
      this.check_changes();
      return conditions;
    },
    check_changes() {
      const { edited_bonus, bonuses_monthly, validation_errors: errors } = this;
      const db_bonus = bonuses_monthly.find(({ id }) => id === edited_bonus.id);
      const is_fields_valid = Object.keys(errors).some((key) => errors[key].some((value) => typeof value === "string"));

      this.has_changes = db_bonus
        ? Object.keys(edited_bonus).some((key) => `${edited_bonus[key]}` !== `${db_bonus[key]}`) && !is_fields_valid
        : !is_fields_valid;
    },
    on_add_bonus() {
      this.modal.show = true;
      this.modal.confirm_text = "Add bonus";
      this.edited_bonus = {
        enable: false,
        value: null,
        accrued_day_of_month: null,
        text: "",
      };
    },
    on_edit_bonus(bonus) {
      const cloned_bonus = clone(bonus);
      this.edited_bonus = {
        ...cloned_bonus,
        value: this.kopecks_currency_units(cloned_bonus.value, "toRubles"),
      };
      this.modal.show = true;
      this.modal.confirm_text = "Save";
    },
    on_close_modal() {
      this.edited_bonus = {};
      this.validation_errors = {};
      this.has_changes = false;
      this.modal.show = false;
    },
    on_save() {
      const {
        id, enable, value, text,
        accrued_day_of_month,
      } = this.edited_bonus;

      this.upsert_bonus_monthly({
        bonus_to_add: {
          id,
          user_id: this.edited_user_id,
          created_by: this.$user.uid,
          accrued_day_of_month,
          enable,
          value: this.kopecks_currency_units(value, "toKopecks"),
          text,
        },
      });
    },
    async upsert_bonus_monthly(mutation_payload) {
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/config/bonus_monthly/upsert_bonus_monthly.gql"),
          variables: mutation_payload,
        })
        .then(() => {
          this.$root.$emit("toast", "Changes saved successfully");
          this.on_close_modal();
        })
        .catch((error) => error_handler(error, this));

      this.$apollo.queries.bonuses_monthly.refetch();
    },
    /**
     * Converts kopecks to rubles and rubles to kopecks.
     * @param {number} value - Value to convert.
     * @param {string} direction - Conversion direction: "toRubles" or "toKopecks".
     * @returns {number} - Converted value.
     */
    kopecks_currency_units(value, direction) {
      let result;
      switch (direction) {
        case "toRubles":
          result = value / 100;
          break;

        case "toKopecks":
          result = Math.round(value * 100);
          break;

        default:
          throw new Error('Invalid direction. Use "toRubles" or "toKopecks".');
      }
      return result;
    },
  },
};
</script>

<style scoped>
.v-data-table th,
.v-data-table td {
  white-space: pre;
}
.comment_text {
  display: inline-block;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-left: 5px;
}

.btn-cancel {
  border: 1px solid #CED5E1 !important;
}

.bonus_header {
  position: relative;
}
.disabled_checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

@media (max-width: 460px) {
  .disabled_checkbox {
    position: static;
    transform: none;
    margin-left: 8px;
  }
}
</style>
