/*= ========================================================================================
  Section name: Config, Oligo
  Inner routes: Aliases
========================================================================================== */

export default [
  {
    path: "/config/oligos/aliases",
    name: "config:aliases",
    meta: {
      menu_name: "Aliases",
      menu_section: "oligos_config",
      icon: "mdi-swap-horizontal-bold",
      required_capacities: ["config"],
      required_teams: ["Oligosynthesis"],
    },
    component: () => import("@/views/config/oligos/aliases.vue"),
  },
  {
    path: "/config/oligos/aliases/:id/edit",
    name: "config:aliases:edit",
    meta: {
      icon: "mdi-swap-horizontal-bold",
      required_capacities: ["config"],
    },
    component: () => import("@/views/config/oligos/aliases.vue"),
  },
];
