<template>
  <v-dialog
    v-if="edited_user"
    :value="dialog"
    max-width="900px"
    @input="on_cancel"
  >
    <v-card class="pb-4">
      <v-card-title>
        <v-row>
          <v-col class="col-10">
            <v-row v-if="!edit_title.is_edited">
              <v-col id="username_edit_btn">
                <span class="heading text-h5">{{ dialog_title }}</span>
                <v-icon
                  v-if="user_has_access_as(['superuser'])"
                  size="22"
                  class="ml-4 mb-1"
                  @click="on_edit_title()"
                >
                  mdi-pencil-outline
                </v-icon>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="modal_field_wrapper col-5 pr-2">
                <v-text-field
                  v-model="edit_title.first_name"
                  dense
                  outlined
                  height="34"
                  class="in-modal"
                  label="first name"
                  hide-details="auto"
                  :error-messages="error_messages.first_name"
                  @focus="touched.first_name = true"
                />
              </v-col>
              <v-col class="modal_field_wrapper col-6 pr-2 pl-0">
                <v-text-field
                  v-model="edit_title.last_name"
                  dense
                  outlined
                  height="34"
                  class="in-modal"
                  label="last name"
                  hide-details="auto"
                  :error-messages="error_messages.last_name"
                  @focus="touched.last_name = true"
                />
              </v-col>
              <v-col class="col-1 pl-0">
                <v-icon
                  size="22"
                  class="mb-1"
                  @click="on_save_title()"
                >
                  mdi-content-save-outline
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-2 d-flex">
            <v-hover
              v-slot="{ hover }"
              class="d-none d-sm-flex"
            >
              <v-btn
                depressed
                class="blue white--text"
                :class="{ 'darken-2': hover }"
                :ripple="false"
                :disabled="save_disabled || !user_has_access_as(['superuser', 'current_user', 'team_lead_of_user', 'tag_owner'])"
                @click="on_save"
              >
                Save
              </v-btn>
            </v-hover>
            <v-icon
              size="20"
              class="ml-auto"
              @click="on_cancel"
            >
              mdi-window-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="col-7 col-sm-4 col-md-3 order-0 order-sm-0">
            <div class="profile-img">
              <div class="img-profile-container">
                <img
                  :class="{ avatar: edited_user.avatar_img }"
                  :src="avatar_img_data"
                  height="200"
                >
              </div>
              <div class="img-input-container ">
                <v-row class="pa-3 w-100">
                  <v-col
                    id="avatar_field"
                    cols="9"
                    class="modal_field_wrapper pa-0 ma-0"
                  >
                    <v-file-input
                      v-model="file"
                      dense
                      outlined
                      :clearable="false"
                      :disabled="!user_has_access_as(['superuser', 'current_user'])"
                      height="34"
                      label="Avatar"
                      hide-details="auto"
                      prepend-icon="mdi-camera"
                      placeholder="Pick an avatar"
                      accept="image/png, image/jpeg, image/bmp"
                      @change="choose_img($event)"
                    />
                  </v-col>
                  <v-col
                    id="avatar_trash_btn"
                    cols="3"
                    class="pa-0 ma-0"
                  >
                    <v-btn
                      icon
                      size="14"
                      class="float-right"
                      :disabled="!user_has_access_as(['superuser', 'current_user'])"
                      @click="delete_profile_img()"
                      @click.stop="() => {}"
                    >
                      <div
                        class="svg-icon"
                        v-html="$icons.trash_2"
                      />
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-5 col-md-6 order-2 order-sm-1">
            <v-row class="ma-0">
              <v-col
                id="email_field"
                cols="8"
                class="pr-2 pl-0 py-1"
              >
                <div class="pl-2 pb-1">
                  Email
                </div>
                <v-text-field
                  v-model="edited_user.username"
                  dense
                  outlined
                  height="34"
                  class="in-modal"
                  hide-details="auto"
                  :disabled="!user_has_access_as(['superuser'])"
                  :error-messages="error_messages.username"
                  @focus="touched.username = true"
                />
              </v-col>
              <v-col
                cols="4"
                class="pl-0 pr-0 py-1"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  offset-x
                  left
                  min-width="auto"
                  transition="scale-transition"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :return-value.sync="edited_user.birthday"
                >
                  <template #activator="{ on, attrs }">
                    <div id="birthday_field">
                      <div class="pl-2 pb-1">
                        Birthday
                      </div>
                      <v-text-field
                        :value="edited_user.birthday"
                        dense
                        outlined
                        readonly
                        height="34"
                        hide-details="auto"
                        :disabled="!user_has_access_as(['superuser', 'current_user'])"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </div>
                  </template>
                  <v-date-picker
                    v-model="computed_date"
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(edited_user.birthday)"
                    >
                      Set
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row
              v-if="ui_teams_items"
              class="ma-0"
            >
              <v-col
                cols="12"
                class="py-1 px-0"
              >
                <div id="teams_select">
                  <div class="pl-2 pb-1">
                    Teams
                  </div>
                  <v-autocomplete
                    v-model="ui_selected_teams"
                    dense
                    chips
                    outlined
                    multiple
                    small-chips
                    deletable-chips
                    class="user-edit-autocomplete"
                    hide-details="auto"
                    :disabled="!user_has_access_as(['superuser'])"
                    :items="ui_teams_items"
                    @change="set_team_ids()"
                  >
                    <template #selection="{ item: { value: id, text: name, head_id } }">
                      <div
                        :id="`teams_item_${id}`"
                        :class="{ head: head_id === $user.uid }"
                        class="badge d-flex align-center my-1 mr-2 mb-0 pa-2"
                      >
                        <span>{{ name }}</span>
                        <v-icon
                          v-if="user_has_access_as(['superuser'])"
                          class="remove_icon ml-1"
                          @click.stop="on_remove_team(id)"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="ui_tags_items"
              class="ma-0"
            >
              <v-col
                cols="12"
                class="py-1 px-0"
              >
                <div id="tags_select">
                  <div class="pl-2 pb-1">
                    Tags
                  </div>
                  <v-autocomplete
                    v-model="ui_selected_tags"
                    dense
                    chips
                    outlined
                    multiple
                    small-chips
                    deletable-chips
                    :min-height="90"
                    class="user-edit-autocomplete"
                    hide-details="auto"
                    :disabled="!user_has_access_as(['superuser', 'tag_owner'])"
                    :items="ui_tags_items"
                    :item-disabled="isTagDisabled"
                    @change="set_tag_ids()"
                  >
                    <template #selection="{ item: { value: id, text: name, owner_id } }">
                      <div
                        :id="`tags_item_${id}`"
                        :class="{ owner: owner_id === $user.uid }"
                        class="badge having d-flex align-center my-1 mr-2 mb-0 pa-2"
                      >
                        <router-link
                          :id="`tag_link_${id}`"
                          :to="{ path: `/tags`, query: { tag_name: name } }"
                        >
                          {{ name }}
                        </router-link>
                        <v-icon
                          v-if="user_has_access_as(['superuser']) || owner_id === $user.uid"
                          class="remove_icon ml-1"
                          @click.stop="on_remove_tag(id)"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-5 col-sm-3 col-md-3 order-1 order-sm-2 pl-4 pl-md-8 d-flex flex-column">
            <v-hover
              v-slot="{ hover }"
              class="d-flex d-sm-none w-100"
            >
              <v-btn
                depressed
                class="blue white--text"
                :class="{ 'darken-2': hover }"
                :ripple="false"
                :disabled="save_disabled || !user_has_access_as(['superuser', 'current_user', 'team_lead_of_user'])"
                @click="on_save"
              >
                Save
              </v-btn>
            </v-hover>
            <div class="d-flex align-center pl-2 pt-8">
              <div id="approved_chbox">
                <v-checkbox
                  v-model="edited_user.approved"
                  class="custom_checkbox my-0 py-0"
                  hide-details="auto"
                  :disabled="!user_has_access_as(['superuser'])"
                />
              </div>
              <div>Approved</div>
            </div>
            <div class="d-flex align-center pl-2 pt-2">
              <div id="superuser_chbox">
                <v-checkbox
                  v-model="is_superuser"
                  class="custom_checkbox my-0 py-0"
                  hide-details="auto"
                  :disabled="!user_has_access_as(['superuser'])"
                />
              </div>
              <div>Superuser</div>
            </div>
            <div
              id="password_btn"
              class="pt-8"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  class="grey lighten-2"
                  :class="{ 'darken-3': hover }"
                  :disabled="!user_has_access_as(['superuser', 'current_user'])"
                  @click="password_dialog = true"
                >
                  <v-icon
                    small
                    class="ml-1 mr-2"
                  >
                    mdi-lock-reset
                  </v-icon>
                  <span class="mr-1">Password</span>
                </v-btn>
              </v-hover>
            </div>
            <BonusesSum
              v-if="user_has_access_as(['superuser', 'current_user', 'team_lead_of_user'])"
              class="mt-auto"
              :monthly_bonuses="monthly_bonuses"
              :current_bonuses="history_bonuses"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <MonthlyBonus
        v-if="user_has_access_as(['superuser', 'current_user', 'team_lead_of_user'])"
        id="monthly_bonus_table"
        :user_can_edit="user_has_access_as(['superuser'])"
        :edited_user_id="user_id"
        @update="set_monthly_bonuses"
      />
      <BonusHistory
        v-if="user_has_access_as(['superuser', 'current_user', 'team_lead_of_user'])"
        id="bonus_history_table"
        :user_can_edit="user_has_access_as(['superuser', 'team_lead_of_user'])"
        :edited_user_id="user_id"
        @update="set_history_bonuses"
      />
    </v-card>
    <PasswordSettingsModal
      :dialog="password_dialog"
      :username="edited_user.username"
      @cancel="password_dialog = false"
    />
  </v-dialog>
</template>

<script>
import debounce from "lodash/debounce.js";
import difference from "lodash/difference.js";
import { cloneDeep } from "lodash";
import { email_regex } from "@/lib/util.js";
import BonusHistory from "./bonus_history.vue";
import MonthlyBonus from "./monthly_bonus.vue";
import BonusesSum from "./bonuses_sum.vue";

export default {
  name: "UserEditModal",
  components: {
    PasswordSettingsModal: () => import("./password_settings_modal.vue"),
    BonusHistory,
    MonthlyBonus,
    BonusesSum,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    user_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      edited_user: null,
      db_roles: null,
      menu: false,
      edit_title: {
        is_edited: false,
        first_name: "",
        last_name: "",
      },
      file: null,
      default_avatar: "https://avatars.mds.yandex.net/get-yapic/null/islands-150",
      password_dialog: false,

      is_superuser: false,
      ui_teams_items: [],
      ui_selected_teams: [],
      ui_tags_items: [],
      ui_selected_tags: [],
      touched: {
        username: false,
        first_name: false,
        last_name: false,
      },
      errors: {
        username: false,
        first_name: false,
        last_name: false,
      },
      error_messages: {
        username: "",
        first_name: "",
        last_name: "",
      },
      error_templates: {
        empty_field: "field cannot be empty",
        invalid_email: "invalid email",
      },
      monthly_bonuses: [],
      history_bonuses: [],
    };
  },
  apollo: {
    user_by_pk: {
      query: require("@/graphql/config/user/get_user_w_his_roles_tags_and_teams.gql"),
      fetchPolicy: "cache-first",
      variables() {
        return { user_id: this.user_id };
      },
      result({ data: { user_by_pk, hasura_role } }) {
        this.db_roles = hasura_role.reduce((acc, role) => {
          acc[role.key] = role.id;
          return acc;
        }, {});
        // TODO: CTX-1764 Added cloneDeep() because: TypeError: Cannot add property approved, object(user_by_pk) is not extensible
        this.edited_user = cloneDeep(user_by_pk);
        this.edited_user.approved = !this.edited_user.disabled;
        this.edited_user.roles = this.edited_user.user_hasura_roles.map(({ hasura_role: { key } }) => key);
        this.is_superuser = this.edited_user.roles.includes("superuser");

        this.edited_user.teams_ids_before_edit = this.edited_user.user_teams.map(({ team: { id } }) => id);
        this.edited_user.teams_ids = [...this.edited_user.teams_ids_before_edit];
        this.ui_selected_teams = this.edited_user.teams_ids;

        this.edited_user.tags_ids_before_edit = this.edited_user.user_tags.map(({ tag: { id } }) => id);
        this.edited_user.tags_ids = [...this.edited_user.tags_ids_before_edit];
        this.ui_selected_tags = this.edited_user.tags_ids;

        const own_tags_ids = new Set(this.owned_tags.map(({ id }) => (id)));
        this.ui_tags_items = this.owned_tags.map(({ id, name, owner_id }) => ({ value: id, text: name, owner_id }));

        for (const { tag: { id, name } } of this.edited_user.user_tags) {
          if (!own_tags_ids.has(id)) {
            this.ui_tags_items.unshift({ value: id, text: name, disabled: !this.$user.is_super() });
          }
        }
      },
      skip() {
        return !this.$user.uid || !this.owned_tags;
      },
    },
    all_teams: {
      query: require("@/graphql/config/team/list.gql"),
      update({ teams }) {
        this.ui_teams_items = teams.map(({ id, name, head_id }) => ({ value: id, text: name, head_id }));
        return teams;
      },
    },
    owned_tags: {
      query: require("@/graphql/config/tag/get_owned_tags.gql"),
      variables() {
        const where = !this.$user.is_super() ? { owner_id: { _eq: this.$user.uid } } : null;
        return {
          where,
        };
      },
      update({ tags }) {
        return tags;
      },
    },
  },
  computed: {
    avatar_img_data() {
      return this.edited_user.avatar_img ?? this.default_avatar;
    },
    dialog_title() {
      return `${this.edited_user.first_name} ${this.edited_user.last_name}`;
    },
    save_disabled() {
      return Object.values(this.edited_user).includes("") || Object.values(this.errors).includes(true);
    },
    computed_date: {
      // v-date-picker accepts ISO 8601 date strings in YYYY-MM-DD format and we display in DD.MM.YYYY format
      get() {
        const date = this.edited_user.birthday ?? new Date(Date.now()).toLocaleString("ru-RU").slice(0, 10);
        return date.split(".").reverse().join("-");
      },
      set(new_value) {
        const date = new_value.split("-").reverse().join(".");
        this.edited_user.birthday = date; // this.$set(this.edited_user, "birthday", date);
      },
    },
  },

  watch: {
    edited_user: {
      deep: true,
      handler: debounce(function () { this.run_validation(); }, 300),
    },
  },

  methods: {
    /**
     * @param {("superuser" | "current_user" | "tag_owner" | "team_lead_of_user")[]} for_roles
     */
    user_has_access_as(for_roles = []) {
      if (for_roles.includes("superuser") && this.$user.is_super()) {
        return true;
      }

      if (for_roles.includes("current_user") && this.$user.uid === this.edited_user.id) {
        return true;
      }

      if (for_roles.includes("tag_owner") && this.owned_tags?.some(({ owner_id }) => owner_id === this.$user.uid)) {
        return true;
      }

      if (for_roles.includes("team_lead_of_user") && this.edited_user.user_teams?.some(({ team: { head_id } }) => (head_id === this.$user.uid && this.edited_user.id !== this.$user.uid))) {
        return true;
      }

      return false;
    },

    choose_img(new_file) {
      if (!new_file) {
        this.edited_user.avatar_img = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(new_file);
      reader.onload = () => {
        this.edited_user.avatar_img = reader.result;
      };
    },
    delete_profile_img() {
      this.edited_user.avatar_img = null;
      this.file = null;
    },
    set_monthly_bonuses(value) {
      this.monthly_bonuses = value;
    },
    set_history_bonuses(value) {
      this.history_bonuses = value;
    },
    set_team_ids() {
      this.edited_user.teams_ids = this.ui_selected_teams;
    },
    set_tag_ids() {
      this.edited_user.tags_ids = this.ui_selected_tags;
    },
    isTagDisabled(item) {
      return item.disabled;
    },
    run_validation() {
      const errors = {
        username: false,
        first_name: false,
        last_name: false,
      };
      const error_messages = {
        username: "",
        first_name: "",
        last_name: "",
      };

      // check requered fields are not empty
      for (const key in errors) {
        if (this.touched[key] && !this.edited_user[key].trim()) {
          errors[key] = true;
          error_messages[key] = this.error_templates.empty_field;
        }
      }

      if (this.touched.username && !email_regex.test(this.edited_user.username)) {
        errors.username = true;
        error_messages.username = this.error_templates.invalid_email;
      }

      this.errors = errors;
      this.error_messages = error_messages;
    },

    on_edit_title() {
      this.edit_title = {
        first_name: this.edited_user.first_name,
        last_name: this.edited_user.last_name,
      };
      this.edit_title.is_edited = true;
    },

    on_save_title() {
      this.edited_user.first_name = this.edit_title.first_name;
      this.edited_user.last_name = this.edit_title.last_name;
      this.run_validation();
      const { first_name, last_name } = this.errors;
      if (first_name || last_name) {
        this.$root.$emit("toast", "fields 'first_name, last_name' cannot be empty", "error");
      } else {
        this.edit_title = {
          ...this.edit_title,
          is_edited: false,
        };
      }
    },

    on_remove_team(team_id) {
      this.ui_selected_teams = this.ui_selected_teams.filter((value) => value !== team_id);
      this.set_team_ids();
    },
    on_remove_tag(tag_id) {
      this.ui_selected_tags = this.ui_selected_tags.filter((value) => value !== tag_id);
      this.set_tag_ids();
    },
    on_close_dialog() {
      this.edited_user = {};
      for (const key in this.touched) this.touched[key] = false;
    },
    on_cancel() {
      this.$emit("cancel");
      this.on_close_dialog();
    },
    async on_save() {
      try {
        for (const key in this.errors) this.edited_user[key] = this.edited_user[key].trim();
        await this.update_user(this.edited_user);
        this.$root.$emit("toast", "Changes saved successfully");
      } catch (error) {
        this.$root.$emit("toast", error.message, "error");
      } finally {
        this.on_close_dialog();
        this.$emit("cancel");
      }
    },

    async update_user(user) {
      const mutation_payload = this.gen_mutation_payload(user);
      const { id: user_id, teams_ids_before_edit, tags_ids_before_edit } = user;

      const update_user_promise = await this.$apollo
        .mutate(this.get_access_based_vars_and_update({
          mutation: require("@/graphql/config/user/update_user_with_his_roles_tags_and_teams.gql"),
          variables: mutation_payload,
          update(cache, { data }) {
            cache.modify({
              id: cache.identify(user),
              fields: {
                user_teams() {
                  const { delete_user_team, insert_user_team } = data;
                  if (delete_user_team && insert_user_team) {
                    const removed_by_the_update_teams_ids = delete_user_team.returning.map(({ team_id }) => team_id);
                    const added_by_the_update_teams_ids = insert_user_team.returning.map(({ team_id }) => team_id);
                    const remained_after_the_update_teams_ids = difference(teams_ids_before_edit, removed_by_the_update_teams_ids);
                    const current_teams_ids = [...remained_after_the_update_teams_ids, ...added_by_the_update_teams_ids];
                    return current_teams_ids.map((id) => ({ user_id, team_id: id, __typename: "user_team" }));
                  }
                },
                user_tags() {
                  const { delete_user_tag, insert_user_tag } = data;
                  if (delete_user_tag && insert_user_tag) {
                    const removed_by_the_update_tags_ids = delete_user_tag.returning.map(({ tag_id }) => tag_id);
                    const added_by_the_update_tags_ids = insert_user_tag.returning.map(({ tag_id }) => tag_id);
                    const remained_after_the_update_tags_ids = difference(tags_ids_before_edit, removed_by_the_update_tags_ids);
                    const current_tags_ids = [...remained_after_the_update_tags_ids, ...added_by_the_update_tags_ids];
                    return current_tags_ids.map((id) => ({ user_id, tag_id: id, __typename: "user_tag" }));
                  }
                },
              },
            });
          },
        }));

      if (update_user_promise.status === "rejected") {
        throw new Error(`Cannot update user: ${JSON.stringify(update_user_promise)}`);
      }
    },

    gen_mutation_payload(user) {
      const {
        id: user_id,
        avatar_img,
        approved,
        username,
        first_name,
        last_name,
        birthday,
        roles,
        teams_ids_before_edit,
        teams_ids,
        tags_ids_before_edit,
        tags_ids,
      } = user;

      const SUPERUSER_ROLE_ID = this.db_roles.superuser;
      const USER_ROLE_ID = this.db_roles.User;

      const edited_user_role_id = roles.includes("superuser") ? SUPERUSER_ROLE_ID : USER_ROLE_ID;
      const role_has_been_changed = roles.includes("superuser") !== this.is_superuser;

      const delete_role_ids = role_has_been_changed ? (this.is_superuser ? [USER_ROLE_ID] : [SUPERUSER_ROLE_ID]) : [edited_user_role_id];
      const added_role_ids = role_has_been_changed ? (this.is_superuser ? [SUPERUSER_ROLE_ID] : [USER_ROLE_ID]) : [edited_user_role_id];
      const deleted_teams_ids = difference(teams_ids_before_edit, teams_ids);
      const added_teams_ids = difference(teams_ids, teams_ids_before_edit);
      const deleted_tags_ids = difference(tags_ids_before_edit, tags_ids);
      const added_tags_ids = difference(tags_ids, tags_ids_before_edit);

      return {
        id: user_id,
        user: {
          avatar_img,
          username,
          first_name,
          last_name,
          birthday: birthday?.split(".").reverse().join(".") ?? null,
          ...(this.user_has_access_as(["superuser"]) && { disabled: !approved }),
        },
        role_to_remove: { _or: delete_role_ids.map((id) => ({ user_id: { _eq: user_id }, hasura_role_id: { _eq: id } })) },
        role_to_add: added_role_ids.map((id) => ({ user_id, hasura_role_id: id })),
        teams_to_remove: { _or: deleted_teams_ids.map((id) => ({ user_id: { _eq: user_id }, team_id: { _eq: id } })) },
        teams_to_add: added_teams_ids.map((id) => ({ user_id, team_id: id })),
        tags_to_remove: { _or: deleted_tags_ids.map((id) => ({ user_id: { _eq: user_id }, tag_id: { _eq: id } })) },
        tags_to_add: added_tags_ids.map((id) => ({ user_id, tag_id: id })),
        can_edit_user_fields: true,
        can_edit_roles: true,
        can_edit_teams: true,
        can_edit_tags: true,
      };
    },

    get_access_based_vars_and_update(mutation_config) {
      const { variables } = mutation_config;
      if (!this.user_has_access_as(["superuser"])) {
        if (this.user_has_access_as(["tag_owner", "current_user"])) {
          variables.can_edit_roles = false;
          variables.can_edit_teams = false;
        } else if (this.user_has_access_as(["tag_owner"])) {
          variables.can_edit_roles = false;
          variables.can_edit_teams = false;
          variables.can_edit_user_fields = false;
        } else if (this.user_has_access_as(["current_user"])) {
          variables.can_edit_roles = false;
          variables.can_edit_teams = false;
          variables.can_edit_tags = false;
        } else {
          variables.can_edit_user_fields = false;
          variables.can_edit_roles = false;
          variables.can_edit_teams = false;
          variables.can_edit_tags = false;
        }
      }

      return {
        ...mutation_config,
        variables,
      };
    },
  },
};
</script>

<style>
.user-edit-autocomplete .theme--light.v-chip:not(.v-chip--active) {
  background: #CAECFF; /* not active chip background */
}

.user-edit-autocomplete .theme--light.v-chip {
  color: #2160EA; /* chip (text) color and active chip background (lighter) */
}

.modal_field_wrapper {
  max-height: 58px;
}

.dialog-content-wrapper {
  min-width: 390px !important;
}

.v-icon.v-icon::after {
  opacity: 0 !important;
}
.custom_checkbox .v-input--selection-controls__ripple {
  position: absolute;
  top: 50%;
  left: -5px;
  transform: translate(0, -50%);
  width: 28px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid #ADB5BD;
  transition: all .3s ease;
  cursor: pointer;
  margin: 0;
}
.custom_checkbox .v-input--selection-controls__ripple::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  right: 1px;
  transform: translate(0, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ADB5BD;
  transition: all .5s ease;
}

.custom_checkbox.v-input--is-label-active .v-input--selection-controls__ripple {
  border-color: #5282ff;
  background-color: #5282ff;
}

.custom_checkbox.v-input--is-label-active .v-input--selection-controls__ripple::after {
  left: auto;
  background-color: #ffffff;
}

.custom_checkbox .v-icon {
  display: none;
}
.v-text-field__slot:has(.v-file-input__text) {
  width: 100%;
}
.v-file-input__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#teams_select .v-select__selections,
#tags_select .v-select__selections {
  margin-bottom: 4px;
}

.remove_icon {
  width: 13px;
  height: 13px;
}
.remove_icon::before {
  font-size: 16px;
}
.badge a {
  text-decoration: none;
}
.badge.head {
  background-color: #CAECFF;
  color: #2160EA;
}
.badge.head .remove_icon::before {
  color: #2160EA;
}
.badge.having {
  background-color: #DBEDDB;
  color: #4EB000;
}
.badge.having .remove_icon::before {
  color: #4EB000;
}

.badge.owner {
  background-color: #4EB000;
  color: #DBEDDB;
}
.badge.owner .remove_icon::before {
  color: #DBEDDB;
}

#tags_select .v-select__slot {
  align-items: flex-start;
}
</style>

<style scoped>
.profile-img {
  max-width: 190px;
}
.img-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
  overflow: hidden;
}
.avatar {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.img-profile-container img {
  width: 30%;
  height: 100%;
  object-fit: contain;
}
.img-input-container {
  margin-top: 10px;
  display: flex;
}
.profile {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 20px 0;
}
.change-wrapper {
  display: flex;
  max-width: 430px;
  justify-content: center;
  margin: auto;
}
.profile-settings {
  margin-top: 15px;
}
.profile-settings__info {
  margin-bottom: 15px;
}
</style>
