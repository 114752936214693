import { get, set, cloneDeep } from "lodash";
import gql from "graphql-tag";
import { vm } from "../main.js"; // eslint-disable-line import/no-cycle

const READ_USER_PREFERENCES_GQL = gql`
  query get_user_preferences($uid: Int!) {
    user(where: { id: { _eq: $uid } }) {
      preferences
    }
  }
`;

const SET_USER_PREFERENCES_GQL = gql`
  mutation update_user_meta($uid: Int!, $_set: user_set_input) {
    update_user(where: { id: { _eq: $uid } }, _set: $_set) {
      returning {
        id
        preferences
      }
    }
  }
`;

export default {
  install(Vue) {
    const _load_preferences = async function () {
      await vm.$apollo
        .query({
          query: READ_USER_PREFERENCES_GQL,
          variables: {
            uid: vm.$user.uid,
          },
        })
        .then((data) => {
          this.preferences = data.data?.user[0]?.preferences || {};
        });
    };

    const _update_preferences = function () {
      return vm.$apollo.mutate({
        mutation: SET_USER_PREFERENCES_GQL,
        variables: {
          uid: vm.$user.uid,
          _set: {
            preferences: {
              ...this.preferences,
            },
          },
        },
      });
    };

    /**
     * remembers a value at the specified path
     * the path may be compound (i.e. "path.to.preference")
     * Necessary objects will be created and nested if necessary
     * @param {String} composite_preference_key
     * @param {Promise<any>} value
     */
    const write_preference = function (composite_preference_key, value) {
      this.preferences = set(cloneDeep(this.preferences), composite_preference_key, value);
      return this._update_preferences();
    };

    const get_preferences = function (compound_key, fallback_value = null) {
      return cloneDeep(get(this.preferences, compound_key, fallback_value));
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$usher = {
      write_preference,
      get_preferences,
      _update_preferences,
      _load_preferences,
    };
  },
};
