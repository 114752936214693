<template>
  <v-card
    outlined
    elevation="0"
    class="ma-4 px-4"
  >
    <v-card-title class="d-flex justify-start justify-sm-center w-100 mb-4">
      <span class="ml-2 text-h5">Bonus History</span>
      <span id="add_bonus">
        <v-icon
          v-if="user_can_edit"
          class="ml-2"
          @click="on_add_bonus"
        >
          mdi-plus-circle-outline
        </v-icon>
      </span>
    </v-card-title>

    <SmartFilter
      type="frontend"
      class="smart_filter mt-4"
      :content="bonuses_history"
      :cells="cells"
      @filtered="on_filtered"
    />
    <div id="bonus_table">
      <v-data-table
        class="transparent c-table"
        :headers="headers"
        :items="filtered_bonuses"
      >
        <template #item.accrued_by="{ item: { id, creator, created_by, created_at } }">
          <div class="text-capitalize font-weight-bold">
            <span
              v-if="created_by"
              :id="`creator_name_${id}`"
            >
              {{ creator.first_name }} {{ creator.last_name[0] }}.
            </span>
            <span
              v-else
              :id="`creator_name_${id}`"
            >
              ROBOT
            </span>
          </div>
          <div :id="`created_at_${id}`">
            {{ format_date(new Date(created_at)) }}
          </div>
        </template>

        <template #item.reason="{ item: { id, reference, reference_id} }">
          <div
            v-if="reference !== null && reference_id !== null"
            class="font-weight-bold"
          >
            <router-link
              class="text-decoration-none"
              :to="{
                name: reference,
                params: {
                  id: reference_id,
                },
              }"
            >
              <span :id="`reason_${id}`">
                {{ to_capitalize(reference) }} #{{ reference_id }}
              </span>
            </router-link>
          </div>
          <div
            v-else-if="reference_id !== null"
            :id="`reason_${id}`"
            class="font-weight-bold"
          >
            Monthly #{{ reference_id }}
          </div>
          <div
            v-else
            :id="`reason_${id}`"
          >
            -
          </div>
        </template>

        <template #item.bonus="{ item: { id, value } }">
          <div
            :id="`pay_value_${id}`"
            class="badge"
          >
            {{ value > 0 ? `+ ${value / 100}` : `- ${Math.abs(value) / 100}` }} ₽
          </div>
        </template>

        <template #item.pay_date="{ item: { id, paid_date } }">
          <div
            v-if="paid_date !== null"
            :id="`paid_date_${id}`"
            class="pay_date"
          >
            {{ format_date(new Date(paid_date)) }}
          </div>
          <div
            v-else
            :id="`paid_date_${id}`"
            class="pay_unpaid"
          >
            Unpaid
          </div>
        </template>

        <template #item.comment="{ item: { id, text } }">
          <div
            :id="`bonus_comment_${id}`"
            class="comment_text"
          >
            {{ text }}
          </div>
        </template>

        <template #item.actions="{ item: bonus }">
          <div
            :id="`bonus_edit_btn_${bonus.id}`"
            class="d-flex justify-end w-100"
          >
            <v-icon
              v-if="user_can_edit && !bonus.paid_date"
              size="22"
              class="ml-4 mb-1"
              @click="on_edit_bonus(bonus)"
            >
              mdi-pencil-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
    <div
      v-if="modal.show"
    >
      <v-dialog
        id="bonus_modal"
        :value="modal.show"
        max-width="400px"
        @input="on_close_modal"
      >
        <v-card class="pb-4">
          <div class="d-flex justify-center text-h5 pa-4">
            <span>Bonus History</span>
            <span id="bonus_modal_remove">
              <v-icon
                v-if="edited_bonus.id"
                class="ml-2"
                @click="on_remove_bonus"
              >
                mdi-trash-can-outline
              </v-icon>
            </span>
          </div>

          <v-card-text class="w-100 pa-4 pt-0">
            <v-row>
              <v-col class="d-flex align-center font-weight-bold">
                Value
              </v-col>
              <v-col
                id="bonus_modal_value"
                class="modal_field_wrapper"
              >
                <v-text-field
                  v-model="edited_bonus.value"
                  dense
                  outlined
                  type="number"
                  hide-details="auto"
                  :rules="rules_validation('value', [
                    (val) => !!val || 'Required field',
                  ])"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col id="bonus_modal_comment">
                <span class="d-flex font-weight-bold mb-2">Comment</span>
                <v-textarea
                  v-model="edited_bonus.text"
                  dense
                  outlined
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-space-between pt-2 pb-0">
            <v-hover v-slot="{ hover }">
              <span id="bonus_modal_save">
                <v-btn
                  depressed
                  :disabled="!has_changes"
                  class="btn-save blue white--text"
                  :class="{ 'darken-2': hover }"
                  @click="on_save"
                >
                  <span>{{ modal.confirm_text }}</span>
                </v-btn>
              </span>
            </v-hover>
            <v-btn
              depressed
              class="btn-cancel transparent"
              @click="on_close_modal"
            >
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { clone } from "lodash";
import SmartFilter from "@lumiprobejs/smart-filter";
import { error_handler } from "@/lib/util.js";
import { format_date } from "@/lib/date";

export default {
  name: "BonusHistory",

  components: {
    SmartFilter,
  },

  props: {
    edited_user_id: {
      type: Number,
      default: null,
    },
    user_can_edit: {
      type: Boolean,
    },
  },
  apollo: {
    bonuses_history: {
      query: require("@/graphql/config/bonus/get_user_bonuses.gql"),
      variables() {
        return {
          where: { user_id: { _eq: this.edited_user_id } },
        };
      },
      update({ bonus: bonus_history }) {
        this.$emit("update", bonus_history);
        return bonus_history.map((bonus) => {
          const {
            creator,
            created_by,
            created_at,
            paid_date,
            reference,
            reference_id,
          } = bonus;

          return {
            ...bonus,
            creator_for_filter: `${created_by ? `${creator.first_name} ${creator.last_name}` : "Robot"} ${format_date(created_at)}`,
            reason_for_filter: reference_id ? (reference ? `${reference} ${reference_id}` : `Monthly ${reference_id}`) : "null",
            unpaid_for_filter: paid_date === null,
          };
        });
      },
      skip() {
        return !this.$user.uid;
      },
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Accrued by",
          value: "accrued_by",
          sortable: false,
        },
        {
          text: "Reason",
          value: "reason",
          sortable: false,
        },
        {
          text: "Bonus",
          value: "bonus",
          sortable: false,
        },
        {
          text: "Pay date",
          value: "pay_date",
          sortable: false,
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],
      cells: [
        {
          text: "Creator",
          value: "creator_for_filter",
          type: "input",
        },
        {
          text: "Reason",
          value: "reason_for_filter",
          type: "input",
        },
        {
          text: "Pay date",
          value: "paid_date",
          type: "date",
          value_type: "date",
        },
        {
          text: "Unpaid",
          value: "unpaid_for_filter",
          type: "select",
          value_type: "boolean",
          options: [
            { text: "Unpaid", value: true },
          ],
        },
      ],
      filtered_bonuses: [],
      modal: {
        show: false,
        confirm_text: "Save",
      },
      has_changes: false,
      validation_errors: {},
      edited_bonus: {},
    };
  },
  watch: {
    edited_bonus: {
      deep: true,
      handler() {
        this.check_changes();
      },
    },
  },
  created() {
    const { query = {} } = this.$route;
    if (query.unpaid_for_filter === undefined) {
      query.unpaid_for_filter = "true";
    }
  },
  methods: {
    format_date,
    to_capitalize(str) {
      return str.replace(/\b\w/g, (l) => l.toUpperCase());
    },
    rules_validation(value_name, conditions = []) {
      const value = this.edited_bonus[value_name];
      this.validation_errors[value_name] = conditions.map((fn) => fn(value));
      this.check_changes();
      return conditions;
    },
    check_changes() {
      const { edited_bonus, bonuses_history, validation_errors: errors } = this;
      const db_bonus = bonuses_history.find(({ id }) => id === edited_bonus.id);
      const is_fields_valid = Object.keys(errors).some((key) => errors[key].some((value) => typeof value === "string"));

      this.has_changes = db_bonus
        ? Object.keys(edited_bonus).some((key) => `${edited_bonus[key]}` !== `${db_bonus[key]}`) && !is_fields_valid
        : !is_fields_valid;
    },
    on_filtered(payload) {
      this.filtered_bonuses = payload;
    },
    on_add_bonus() {
      this.modal.show = true;
      this.modal.confirm_text = "Add bonus";
      this.edited_bonus = {
        value: null,
        text: "",
      };
    },
    on_edit_bonus(bonus) {
      this.edited_bonus = clone(bonus);
      this.modal.show = true;
      this.modal.confirm_text = "Save";
    },
    on_remove_bonus() {
      this.delete_bonus({
        id: this.edited_bonus.id,
      });
    },
    on_close_modal() {
      this.edited_bonus = {};
      this.validation_errors = {};
      this.has_changes = false;
      this.modal.show = false;
    },
    on_save() {
      const {
        id, paid_date, value, text,
        reference, reference_id,
      } = this.edited_bonus;

      this.upsert_bonus({
        bonus_to_add: {
          id,
          user_id: this.edited_user_id,
          created_by: this.$user.uid,
          paid_date,
          reference,
          reference_id,
          value,
          text,
        },
      });
    },
    async upsert_bonus(mutation_payload) {
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/config/bonus/upsert_bonus.gql"),
          variables: mutation_payload,
        })
        .then(() => {
          this.$root.$emit("toast", "Changes saved successfully");
          this.on_close_modal();
        })
        .catch((error) => error_handler(error, this));

      this.$apollo.queries.bonuses_history.refetch();
    },
    async delete_bonus(mutation_payload) {
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/config/bonus/remove_bonus_by_pk.gql"),
          variables: mutation_payload,
        })
        .then(() => {
          this.$root.$emit("toast", "Bonus removed successfully");
          this.on_close_modal();
        })
        .catch((error) => error_handler(error, this));

      this.$apollo.queries.bonuses_history.refetch();
    },
  },
};
</script>

<style>
.v-menu__content--fixed {
  z-index: 999 !important;
}
</style>

<style scoped>
.smart_filter {
  position: relative;
}

.v-data-table th,
.v-data-table td {
  white-space: pre;
}

.btn-cancel {
  border: 1px solid #CED5E1 !important;
}

.comment_text {
  display: inline-block;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-left: 5px;
}

.pay_date {
  color: #4EB000;
}
.pay_unpaid {
  color: #FF2305;
}
</style>
