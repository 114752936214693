import { render, staticRenderFns } from "./monthly_bonus.vue?vue&type=template&id=0b8834b3&scoped=true"
import script from "./monthly_bonus.vue?vue&type=script&lang=js"
export * from "./monthly_bonus.vue?vue&type=script&lang=js"
import style0 from "./monthly_bonus.vue?vue&type=style&index=0&id=0b8834b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8834b3",
  null
  
)

export default component.exports