/*= ========================================================================================
  Section name: Config
  Inner routes: Equipment Types
========================================================================================== */
export default {
  path: "/config/equipment-types",
  name: "equipment_types",
  meta: {
    menu_name: "Equipment types",
    menu_section: "config",
    icon: "mdi-microscope",
    required_capacities: ["equipment_type"],
    required_teams: ["Oligosynthesis"],
  },
  component: () => import("@/views/config/equipment_types.vue"),
};
