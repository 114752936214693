// This file contains different project-wide utility functions
import cloneDeep from "lodash/cloneDeep.js";
import set from "lodash/set.js";

export const CRITERIA_MODS = Object.freeze(["volume", "mass", "amount", "au", "count"]);

/**
 * @param {Array<Object>} objects array of objects
 * @param {String} key object property name (property value can be String or Number)
 * @param {Boolean} reversed reversed sorting order (default: false)
 * @returns {Array<Object>} the same array of objects, sorted (in place) by property value [key]
 */
export const in_place_sort_by = (objects, key, reversed = false) => objects
  .sort(({ [key]: a_value }, { [key]: b_value }) => {
    const [a, b] = reversed ? [b_value, a_value] : [a_value, b_value];
    if (a < b) return -1;
    if (a > b) return +1;
    return 0;
  });

export function Deferred() {
  // eslint-disable-next-line unicorn/no-this-assignment
  const self = this;
  this.promise = new Promise((resolve, reject) => {
    self.reject = reject;
    self.resolve = resolve;
  });
}

/**
 * This method moves some element in an array from old position to a new one and shifts other elements (we're not swapping two elements)
 * @param {array} arr a given array
 * @param {Number} from initial element index
 * @param {Number} to target element index
 * @returns {array}
 */
export const move = (arr, from, to) => {
  const clone = cloneDeep(arr);
  clone.splice(to, 0, clone.splice(from, 1)[0]);
  return clone;
};

/**
 * Same as move, but everything is done in-place
 */
export const shift_and_insert = (arr, from, to) => {
  const cut_out = arr.splice(from, 1)[0];
  arr.splice(to, 0, cut_out);
};

/**
 *
 * @param {String} str
 * @param {number} length
 * @returns {String} - truncated string
 */
export const truncate = (str, length) => ((str.length > length) ? `${str.slice(0, length - 1)}...` : str);

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 */
export function hash_code(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.codePointAt(i);
    hash = (hash << 5) - hash + chr;
    hash = Math.trunc(hash); // Convert to 32bit integer
  }
  return hash;
}

/**
 * Recursively applies values from the {@link with_what} and stores them under the same keys in {@link target}.
 * If a key doesn't exist in {@link target} it is created.
 * If a field's value is of type object in both {@link target} and {@link with_what}, the recursion goes deeper.
 *
 * @example
 * ```javascript
 * const a = { field: { nested: 1 } };
 * const b = { field: { nested: 2 } };
 * const deep_override(a, b) // { field: { nested: 2 } }
 * ```
 * @param {Object} target
 * @param {Object} with_what
 * @returns {Object} result of override
 */
export const deep_override = (target, with_what) => {
  for (const key of Object.keys(with_what)) {
    const replace_fully = typeof with_what[key] !== "object"
      || (Array.isArray(with_what[key]) && !Object.hasOwn(target, key))
      || (Object.hasOwn(target, key) && typeof target[key] !== typeof with_what[key]);
    // eslint-disable-next-line no-param-reassign
    target[key] = replace_fully
      ? with_what[key]
      : deep_override(target[key] || {}, with_what[key]);
  }
  return target;
};

export const is_object = (x) => Object.prototype.toString.call(x) === "[object Object]";

/**
* checks for differences in two calibration objects.
* By design of the page, this function is not meant to check
* 1. if there are new fields in the later version
* 2. or if some of the fields from the initial version have been deleted
* 3. or that some of the fields have changed types
* since the structure of the calibration is unchanged
*
* if one of the values in an array has been changed, the whole array
* is deemed updated
*
* @param {Object} a - initial version of the calibration object
* @param {Object} b - updated version of the calibration object
* @param {Array} skip - array of field names that should not participate in the comparison
* @returns [Object | Array, String]
*/
export const calculate_object_delta = (a, b, skip = []) => {
  const res_obj = {};
  let res_flag = "unchanged";
  if (Array.isArray(a) || is_object(a)) {
    for (const key of Object.keys(a)) {
      if (skip.includes(key)) continue;
      const [deeper_comparison, flag] = calculate_object_delta(
        a[key],
        b[key],
        skip,
      );

      if (flag === "updated") {
        res_flag = "updated";
        // if the array has been altered, override it wholly with the older version
        res_obj[key] = Array.isArray(a[key]) ? b[key] : deeper_comparison;
      }
    }
    return [res_obj, res_flag];
  }
  if (a !== b) return [b, "updated"];

  return [null, "unchanged"];
};

// Email address, most used cases <- https://regex101.com/r/mX1xW0/1
export const email_regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

export const error_handler = (error, _this_) => {
  console.error(error);
  if (error.networkError) {
    _this_.$root.$emit(
      "toast",
      "Changes have not been saved. Please check your connection.",
      "error",
    );
  } else {
    const reason = error.graphQLErrors?.map((e) => e.extensions?.internal?.error?.message).join("\n");
    _this_.$root.$emit("toast", `An error occurred: ${reason || error}`, "error");
  }
  return error;
};

export const print_object = (obj) => {
  let res = "";
  for (const k in obj) {
    res += typeof obj[k] === "object"
      ? `\n${k}: ${print_object(obj[k]).split("\n").join("\n  ")}`
      : `\n${k}: ${obj[k]}`;
  }
  return res;
};

export const sleep = async (time_ms) => {
  await new Promise((resolve) => { setTimeout(resolve, time_ms); });
};

/**
 * Converts a "flat" object into a "deep" object using keys as paths.
 * Keys with a value of ignore_value are ignored.
 * For example:
 * const flat_object = { "a[0].b": "xyz", "c[0].d": false, "e.f.g": [3, 2, 1] };
 * get_deep_object_from_flat_object(flat_object, false);
 * // => { a: [{ b: "xyz" }], e: { f: { g: [3, 2, 1] } } }
 * @param {object} flat_object
 * @param {any} ignore_value
 * @returns {object}
 */
export const get_deep_object_from_flat_object = (flat_object, ignore_value) => {
  const deep_object = {};

  for (const key_path in flat_object) {
    const value = flat_object[key_path];
    if (value === ignore_value) continue;
    set(deep_object, key_path, value);
  }

  return deep_object;
};

/**
 * Returns a deep copy without undefined values and, for arrays, without empty elements
 * @param {Object | Array} source
 * @returns {Object | Array}
 */
export const get_deep_copy_without_undefined_empty_values = (source) => {
  const result = Array.isArray(source) ? [] : {};

  for (const key in source) {
    const old_value = source[key];
    if (old_value === undefined) continue;

    const new_value = (typeof old_value !== "object") || (old_value === null)
      ? old_value
      : get_deep_copy_without_undefined_empty_values(old_value);

    if (Array.isArray(result)) {
      result.push(new_value);
    } else {
      result[key] = new_value;
    }
  }

  return result;
};
