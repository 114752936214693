/*= ========================================================================================
  Section name: Config, Oligos
  Inner routes: hplc_methods
========================================================================================== */

export default [
  {
    path: "/config/oligos/hplc_methods",
    name: "config:hplc_methods",
    meta: {
      menu_name: "HPLC methods",
      menu_section: "oligos_config",
      icon: "mdi-chart-bell-curve",
      required_capacities: ["config"],
      required_teams: ["Oligosynthesis"],
    },
    component: () => import("@/views/config/oligos/hplc_methods.vue"),
  },
  {
    path: "/config/oligos/hplc_method/:id/edit",
    name: "config:hplc_methods:edit",
    meta: {
      required_capacities: ["config"],
    },
    component: () => import("@/views/config/oligos/hplc_methods.vue"),
  },
];
