/*= ========================================================================================
  Section name: Config, Oligo
  Inner routes: Oligomer Types
========================================================================================== */

export default [
  {
    path: "/config/oligos/types",
    name: "oligomer_types",
    meta: {
      menu_name: "Oligomer types",
      menu_section: "oligos_config",
      icon: "mdi-dna",
      required_capacities: ["config"],
      required_teams: ["Oligosynthesis"],
    },
    component: () => import("@/views/config/oligos/oligomer_types.vue"),
  },
];
