<template>
  <div class="font-weight-bold">
    <div v-if="monthly_sum">
      Monthly Bonus:
      <span class="ml-2 text-no-wrap">{{ monthly_sum }}</span>
    </div>
    <div v-if="current_sum">
      Current unpaid:
      <span class="ml-2 text-no-wrap">{{ current_sum }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BonusSum",

  props: {
    monthly_bonuses: {
      type: Array,
      default: () => [],
    },
    current_bonuses: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    monthly_sum() {
      return this.get_sum(this.monthly_bonuses);
    },
    current_sum() {
      return this.get_sum(this.current_bonuses);
    },
  },

  methods: {
    get_sum(bonuses) {
      let result = 0;
      for (const bonus of bonuses) {
        if (bonus.enable === undefined || bonus.enable) {
          result += bonus.value;
        }
      }
      return result > 0 ? `+ ${Math.abs(result / 100)}`
        : (result < 0 ? `- ${Math.abs(result / 100)}` : null);
    },
  },
};
</script>
