/*= ========================================================================================
  Section name: Config, Oligo
  Inner routes: Scheduler
========================================================================================== */

export default [
  {
    path: "/config/oligos/scheduler",
    name: "config:scheduler",
    meta: {
      menu_name: "Scheduler",
      menu_section: "oligos_config",
      icon: "mdi-clock-end",
      required_capacities: ["config"],
      required_teams: ["Oligosynthesis"],
    },
    component: () => import("@/views/config/oligos/scheduler.vue"),
  },
];
